@import '../../assets/scss/index.scss';
.responsive-image-wrapper {
  margin: 0 auto;
  position: relative;
  text-align: center;
  .responsive-image {
    max-width: 100%;
    object-fit: contain;
  }
}

@import "../assets/scss/index.scss";
.content-page {
  h3 {
    font-family: "Bellfort";
    color: $primary-highlight;
    font-size: 2rem;
  }
  h4 {
    color: $primary;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  a {
    text-decoration: none;
    color: $primary;
  }
  p {
    color: $primary;
  }
}


#contactForm {
  input,
  textarea,
  select {
    width: 100%;
  }

  input[type="checkbox"] {
    width: auto;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }

  .inputRow > div {
    margin-right: 0.5rem;
  }

  .inputRow > div ~ div {
    margin-left: 0.5rem;
    margin-right: 0;
  }
}

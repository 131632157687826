@import '../../assets/scss/index.scss';

.slider-tile {
  .slider-headline {
    color: $primary;
    font-weight: bold;
    font-size: 1.3125rem;
    font-family: 'GothamBold';
    text-transform: uppercase;
  }
  img {
    max-width: 100%;
    object-fit: contain;
  }
  .slider-text {
    color: $primary;
    font-family: 'GothamBook';
    height: calc(4 * $line-height-p);
  }
}

.slick-track {
  display: flex;
  align-items: flex-end;
}

.slick-arrow {
  top: 40%;
}

@import '../../assets/scss/index.scss';

.footer {
  background-color: $primary-highlight;
  > div {
    text-align: center;
  }
  h2 {
    color: $white;
    padding-bottom: 1.5rem;
    font-family: 'Gothambold';
  }
  a,
  p {
    color: $white;
    text-decoration: none;
  }
  .container {
    flex-wrap: wrap;

    .footer-link {
      padding-right: 0.5em;
      &:not(:first-child) {
        &:before {
          content: '|';
          padding-right: 0.5em;
          color: $white;
        }
      }
    }
  }
}

@import '../../assets/scss/index.scss';

$rotation-down: rotate(90deg);
$rotation-up: rotate(270deg);

@mixin open-to-top {
  flex-direction: column-reverse;
  .info-tile-hover {
    .info-tile-expand {
      transform: $rotation-down;
      &.is-active {
        transform: $rotation-up;
      }
    }
    &:hover {
      .responsive-image-wrapper {
        transform: scale(1.02);
      }
      .info-tile-expand {
        transform: scale(1.3) $rotation-down;
        &.is-active {
          transform: $rotation-up;
        }
      }
    }
  }
}

@mixin open-to-bottom {
  flex-direction: column;
  .info-tile-hover {
    .info-tile-expand {
      transform: $rotation-up;
      &.is-active {
        transform: $rotation-down;
      }
    }
    &:hover {
      .responsive-image-wrapper {
        transform: scale(1.02);
      }
      .info-tile-expand {
        transform: scale(1.3) $rotation-up;
        &.is-active {
          transform: scale(1.3) $rotation-down;
        }
      }
    }
  }
}

.info-tile-wrapper {
  display: flex;
  flex-direction: column;
  &.info-tile-wrapper-3,
  &.info-tile-wrapper-4,
  &.info-tile-wrapper-5 {
    @include open-to-top();
  }

  &.info-tile-wrapper-2 {
    flex-direction: column-reverse;
    @include open-to-top();
    @include media-breakpoint-up(xl) {
      @include open-to-bottom();
    }
  }
}

.info-tile {
  &.is-active {
    background-color: $background-beige;
    border-radius: 1rem;
    h2 {
      color: $primary-highlight;
      font-weight: bold;
    }
  }
  .info-tile-hover {
    position: relative;
    z-index: 6;
    transition: all 0.5s;
    &:hover {
      .responsive-image-wrapper {
        transform: scale(1.02);
      }
      .info-tile-expand {
        transform: scale(1.3) $rotation-up;
        &.is-active {
          transform: scale(1.3) $rotation-down;
        }
      }
    }
  }
  .info-tile-expand {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 5;
    padding: 1rem;
    transition: transform 0.2s;
    transform: $rotation-up;

    // two transparent steps at the end are necessary, otherwise the fade effect
    // collides with the bounding box
    background: radial-gradient(
      $white,
      rgba(248, 244, 236, 0.8),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    &.is-active {
      transform: $rotation-down;
    }
    img {
      width: 1rem;
      @include media-breakpoint-up(lg) {
        width: unset;
      }
    }
  }
  .tile-title {
    text-align: center;
    line-height: 1em;
    height: 2em;
    font-size: 1.125rem;

    @include media-breakpoint-up(sm) {
      font-size: 1.5rem;
    }
  }

  .tile-body {
    max-height: 0;
    background-color: $background-beige;
    border-radius: 1rem;
    transition: max-height 0.01s;

    overflow: hidden;
    opacity: 0;
    width: 100vw;

    @include media-breakpoint-up(md) {
      transition: max-height 0.8s, opacity 0.5s;
    }

    &.is-open {
      opacity: 1;
      z-index: 10;
      max-height: 90em;
      height: unset;
      @include media-breakpoint-up(lg) {
        max-height: 60rem;
        transition: max-height 0.8s, opacity 0.5s;
      }
      @include media-breakpoint-up(xl) {
        transition: opacity 0.5s;
      }
    }

    .tile-body-content {
      padding-top: 2rem;
      p {
        margin: 2rem 0;

        a {
          color: $primary-highlight;
        }
      }
    }
    .up-arrow {
      img {
        &:hover {
          cursor: pointer;
        }
        transform: rotate(90deg);
      }
    }
    .tile-narrow {
      img {
        @include media-breakpoint-up(lg) {
          width: 90%;
          margin-right: -2rem;
        }

        @include media-breakpoint-up(xl) {
          width: 83%;
          margin-right: -2rem;
        }
      }
    }
  }

  &:nth-child(2) {
    .tile-body {
      left: -100%;
    }
  }
  &:nth-child(3) {
    .tile-body {
      left: 0;
      @include media-breakpoint-up(xl) {
        left: -200%;
      }
    }
  }

  &:nth-child(4) {
    .tile-body {
      left: -100%;

      @include media-breakpoint-up(xl) {
        left: 0;
      }
    }
  }
  &:nth-child(5) {
    .tile-body {
      left: 0;
      @include media-breakpoint-up(xl) {
        left: -100%;
      }
    }
  }
  &:nth-child(6) {
    .tile-body {
      left: -100%;
      @include media-breakpoint-up(xl) {
        left: -200%;
      }
    }
  }
}

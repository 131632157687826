@import '../../assets/scss/index.scss';

.header {
  background-image: url(../../assets/images/background.png);
  .burgermenu-container {
    position: absolute;
    @include media-breakpoint-up(lg) {
      position: unset;
    }
  }
  .logo-wrapper {
    margin-bottom: 3em;
    .logo {
      height: 6em;
      @include media-breakpoint-up(sm) {
        height: 11em;
      }
    }
  }
  .slogan-wrapper {
    position: relative;
    text-align: center;
    width: 14.5rem;
    margin: 0 auto;
    margin-bottom: 2rem;

    @include media-breakpoint-up(sm) {
      width: 17.5rem;
    }
    @include media-breakpoint-up(lg) {
      text-align: center;
      margin: 0;
    }

    .company-slogan {
      text-transform: uppercase;
      white-space: nowrap;
      display: flex;

      h1 {
        font-size: 2rem;
      }
      @include media-breakpoint-up(sm) {
        h1 {
          font-size: 2.625rem;
        }
      }
    }

    .company-slogan-1 {
      margin-top: 2rem;

      justify-content: flex-start;
      margin-top: 0;
    }
    .company-slogan-2 {
      justify-content: flex-end;
    }
    .leaves {
      position: absolute;
      top: -1.5rem;
      right: -2rem;
      width: 5rem;
      height: 5rem;

      @include media-breakpoint-up(sm) {
        width: 8rem;
        height: 8rem;
        right: unset;
        left: 15rem;
        top: -2rem;
      }

      @include media-breakpoint-up(lg) {
        left: 15rem;
        top: 2rem;
      }
    }
  }
}

@import '../../assets/scss/index.scss';

$openWidth: 11rem;

.burger-menu-wrapper {
  position: relative;
  left: 0;
  z-index: 13;
  height: 2rem;
  cursor: pointer;
  @include media-breakpoint-up(lg) {
    left: 1rem;
  }
  .burger-menu-button {
    width: 2rem;
    height: 2rem;
    .burger-bar {
      width: 100%;
      height: 0.25rem;
      margin: 0.25rem;
      background-color: $primary;
      border-radius: 0.2rem;
    }
  }
  .burger-menu-menu-wrapper {
    position: absolute;
    width: 0;
    left: 0;
    z-index: 10;
    margin: 5px;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    .burger-menu-menu {
      padding: 0.5rem;
      padding-top: 1rem;
      padding-bottom: 2rem;
      width: calc($openWidth - 1rem);
      border: 1px solid $primary;
      border-radius: 1rem;
      background-color: white;
      overflow: hidden;
      margin: 2px;
      .close-button {
        position: absolute;
        right: 2rem;
        top: 1rem;
        width: 1.25rem;
        height: 1.25rem;

        transform: rotate(45deg);
        transition: all 0.5s;
        &:hover {
          transform: scale(1.1) rotate(45deg);
        }
      }
      ul {
        margin: 0;
      }
      nav {
        a {
          font-size: 1.5rem;
          color: $primary;
          text-decoration: none;
        }
      }
    }
    &.is-open {
      width: $openWidth;
      @include media-breakpoint-up(lg) {
        left: -8rem;
      }
    }
  }
}

@import './colors.scss';
@import './variables.scss';
@font-face {
  font-family: 'Bellfort';
  src: url('../fonts/Bellfort-Draw.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamBold';
  src: url('../fonts/GothamCondensed-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamBook';
  src: url('../fonts/GothamCondensed-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

* {
  font-size: $font-size-p;
  letter-spacing: 0.063rem;
  color: $primary;
  line-height: $line-height-p;
  font-family: 'GothamBook';
}

h1,
span.like-h1 {
  font-size: 1.5rem;
  font-family: 'Bellfort';
  letter-spacing: 0.313rem;
  color: $primary-highlight;
  line-height: 1em;
  font-weight: bold;
  @include media-breakpoint-up(xs) {
    font-size: 2.125rem;
  }
  @include media-breakpoint-up(sm) {
    font-size: 2.625rem;
  }
}

h2 {
  font-family: 'GothamBook';
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 0.125rem;
  color: $primary;
  white-space: pre-line;
}

h4 {
  font-family: 'Bellfort';
  color: $primary-highlight;
}

p,
li {
  font-family: 'GothamBook';
  font-size: $font-size-p;
  letter-spacing: 0.063rem;
  margin-bottom: 0.5rem;
  color: $primary;
  line-height: $line-height-p;
}

ul {
  list-style-image: url('../images/Blatt_Aufzaehlung.png');
  li {
    margin-bottom: 0.5rem;
    span {
      position: relative;
      left: 0.5rem;
    }
  }
}

$font-size-root: 1em;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import './variables.scss';
@import './colors.scss';

.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  @include media-breakpoint-up(xl) {
    max-width: map-get($container-max-widths, 'xl');
  }
}

mt-0 {
  margin-top: 0;
}
